<template>
  <div v-if="content && content.length > 0" class="w-full">
    <div class="grid auto-cols-fr w-full">
      <div v-for="(bucket, index) in content" :key="index" class="row-start-1">
        <div class="bg-gray-900 text-white font-bold px-4 py-4">
          {{ bucket.title }}
        </div>
        <div
          v-if="bucket.content && item.content.opened"
          class="markdown-render overflow-y-hidden text-sm px-4 py-2"
          style="max-height: 200px;"
          v-html="bucket.content"
        />
      </div>
    </div>
    <div v-if="!(item.content && item.content.opened)" class="w-full text-sm text-gray-700 px-4 py-4">
      This content section is hidden until the user opens it. Unopened blocks do not count towards SEO rankings.
    </div>
  </div>
  <div v-else class="flex flex-col items-center justify-center space-y-2 px-4 py-8">
    <p class="text-lg">
      Info Buckets Block
    </p>
    <p class="text-sm text-gray-700">
      Content for this block is taken from the Settings area.
    </p>
  </div>
</template>
<script>
export default {
  friendlyName: 'Info Buckets Block',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    content () {
      return this.$store.state.editor.info_bucket_items
    }
  }
}
</script>
